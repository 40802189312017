import { DocumentIcon, Button, InfoIcon, TicketIcon } from "../components"
import { useEffect } from "react"
import { Link } from "react-router-dom";

export function Homepage() {
    useEffect(() => {
        document.title = "TorriLAN"
    }, [])
    return (
        <div className="wrapper">
            <div className="container">
                <h1>TorriLAN 2024</h1>
                <p>Gjør deg klar for årets LAN!<br />15 - 17 November 2024<br />Kjøp billett nå!</p>
                <div className="buttons-wrapper">
                    <Button as={Link} to="/tickets">
                        <TicketIcon size={30} />
                        Kjøp billetter
                    </Button>
                    <Button as={Link} to="/info">
                        <InfoIcon size={30} />
                        Informasjon
                    </Button>
                    <Button as={Link} to="/samtykkeskjema">
                        <DocumentIcon size={30} />
                        Foresatt samtykkeskjema
                    </Button>
                    <Button as={Link} to="/seatmap">
                        <DocumentIcon size={30} />
                        Seatmap
                    </Button>
                </div>
            </div>
        </div>
    )
}
