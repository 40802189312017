import { Button, TicketIcon, BackButton } from "../components"
import { useEffect } from "react"
//import { createHash } from 'crypto'


const hashValue = val =>
    new Promise(resolve =>
        setTimeout(
            () => resolve(/*createHash('sha256').update(val, "utf8").digest('hex')*/),
            0
        )
    )

export function Seatmap() {
    useEffect(() => {
        document.title = "TorriLAN - Seatmap"
    }, [])
    let hash = null
    let data = null
    let selectedSeat = null
    let phoneNumber = null
    let pinNumber = null
    async function selectSeat(seat) { // seat is a DOM element
        console.log(seat)
        // clear all messages
        // clear all selected seats
        const seats = document.getElementsByClassName("seat")
        if (seat.classList.contains("occupied")) {
            return
        }
        for (let i = 0; i < seats.length; i++) {
            const seat = seats[i]
            seat.classList.remove("selected")
        }
        const message = document.getElementById("message")
        seat.classList.add("selected")
        selectedSeat = seat.id.split("-")[1]


        message.innerHTML = `<p>Ønsker du å reservere sete: ${seat.id.split("-")[1]}</p>`
        //message.appendChild(button)
    }
    async function reserveSeat() {
        if (!validateInput()) {
            return
        }
        if (selectedSeat === null) {
            return
        }
        const body = {
            phoneNumber: phoneNumber,
            pinCode: pinNumber,
            seatNumber: selectedSeat
        }
        const respone = await fetch("https://helloworld-ugnct5qyiq-uc.a.run.app/reserve", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        //console.log(respone)
        if (respone.status !== 200) {
            alert("Noe gikk galt: " + await respone.text())
            await refreshSeatmap()
            return
        }
        alert("Du har reservert sete: " + selectedSeat)
        await refreshSeatmap()
        localStorage.setItem("phoneNumber", phoneNumber)
        localStorage.setItem("pinNumber", pinNumber)
        return
    }
    async function validateInput() {
        const errors = []
        phoneNumber = null
        pinNumber = null
        hash = null
        const phoneNumberInput = document.getElementById("phoneNumber").value
        const pinNumberInput = document.getElementById("pinNumber").value
        if (!phoneNumberInput) {
            return false
        }
        if (phoneNumberInput === "") {
            return false
        }
        if (pinNumberInput === "") {
            if (document.getElementById("pinNumber").type === "password") {
                localStorage.removeItem("phoneNumber")
                localStorage.removeItem("pinNumber")
                document.getElementById("pinNumber").type = "text"
            }
            return false
        }
        if (phoneNumberInput.length !== 8) {
            errors.push("Telefon nr må være 8 siffer")
        }
        if (pinNumberInput.length !== 8) {
            errors.push("Pin koden er 8 tegn")
        }
        phoneNumber = document.getElementById("phoneNumber").value
        pinNumber = document.getElementById("pinNumber").value.toUpperCase()

        const errorDiv = document.getElementById("errors")
        errorDiv.innerHTML = ""
        if (errors.length > 0) {
            for (let i = 0; i < errors.length; i++) {
                const error = errors[i]
                const p = document.createElement("p")
                p.innerHTML = error
                errorDiv.appendChild(p)
            }
            return false
        }

        hash = await hashValue(`${pinNumber.substring(0, 2)}${phoneNumber}`)
        console.log(hash)
        renderSeats()
        return true
    }
    async function refreshSeatmap() {
        const response = await fetch("https://helloworld-ugnct5qyiq-uc.a.run.app/occupiedSeats")
        data = await response.json()
        await renderSeats()
    }
    async function renderSeats() {
        if (!data) {
            return
        }
        const seats = document.getElementsByClassName("seat")
        const occupied = data.occupiedSeats
        const seatOwners = data.seatOwners
        for (let i = 0; i < seats.length; i++) {
            const seat = seats[i]
            seat.classList.remove("occupied")
            seat.classList.remove("ownSeat")
        }
        if (hash) {
            for (let i = 0; i < seatOwners.length; i++) {
                const seatOwner = seatOwners[i]
                if (seatOwner.hash === hash) {
                    const seat = document.getElementById(`seat-${seatOwner.seatNumber}`)
                    seat.classList.add("ownSeat")
                }
            }
        }
        for (let i = 0; i < occupied.length; i++) {
            const seatNumber = occupied[i]
            const seat = document.getElementById(`seat-${seatNumber}`)
            seat.classList.add("occupied")
        }
    }
    async function autoNumberSeatmap() {
        const seatmap = document.getElementById("Speilsalen")
        const rows = seatmap.children
        let seatNumber = 1
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i]
            const seatGroups = row.children
            const seats = []
            for (let j = 0; j < seatGroups.length; j++) {
                const seatGroup = seatGroups[j]
                const seatGroupSeats = seatGroup.children
                for (let k = 0; k < seatGroupSeats.length; k++) {
                    const seat = seatGroupSeats[k]
                    seats.push(seat)
                }
            }
            console.log(seats)
            for (let j = 0; j < seats.length; j++) {
                const seat = seats[j]
                seat.id = `seat-${seatNumber}`
                seat.innerHTML = `<p>${seatNumber}</p>`
                seat.onclick = () => selectSeat(seat)
                seatNumber++
            }
        }
        await refreshSeatmap()
    }
    useEffect(() => {
        autoNumberSeatmap()
        const phoneNo = localStorage.getItem("phoneNumber")
        if (phoneNo) {
            document.getElementById("phoneNumber").value = phoneNo
        }
        const pinNo = localStorage.getItem("pinNumber")
        if (pinNo) {
            document.getElementById("pinNumber").type = "password"
            document.getElementById("pinNumber").value = pinNo
        }
        validateInput()
    }, [])
    function Seats({ numberOfSeats }) {
        const seats = []
        for (let i = 0; i < numberOfSeats; i++) {
            seats.push(<div className="seat"></div>)
        }
        return (seats)
    }
    function SeatGroupV({ numberOfGroups, numberOfSeatPerGroup }) {
        const seatGroups = []
        for (let i = 0; i < numberOfGroups; i++) {
            seatGroups.push(<div className="seatGroupV">
                <Seats numberOfSeats={numberOfSeatPerGroup}></Seats>
            </div>)
        }
        return (seatGroups)
    }
    return (
        <>
            <BackButton />
            <div className="mm">
                <div className="container">
                    <div className="seatmap-header">
                        <h1>Seatmap</h1>
                        <div id="message"></div>

                        <div id="login">
                            <p>For å reservere sete må du først fylle inn telefon nr og pin kode som er sendt til deg på tekstmelding</p>
                            <div id="errors"></div>
                            <label for="phoneNumber">Telefon nr:</label>
                            <input className="textInput" onKeyUp={validateInput} id="phoneNumber" type="text" placeholder="Skriv inn telefon nr" />
                            <label for="pinNumber">Pin kode:</label>
                            <input className="textInput" onKeyUp={validateInput} id="pinNumber" type="text" placeholder="Pin kode" />
                        </div>
                        <p></p>

                        <Button className="reserve" onClick={reserveSeat}>
                            <TicketIcon size={30} />
                            Reserver sete
                        </Button>
                    </div>
                    <div className="seatmap" id="Speilsalen">
                        <div className="seatmapRow" id="row1">
                            <SeatGroupV numberOfGroups={9} numberOfSeatPerGroup={2}></SeatGroupV>
                        </div >
                        <div className="seatmapRow" id="row2">
                            <SeatGroupV numberOfGroups={9} numberOfSeatPerGroup={2}></SeatGroupV>
                        </div >
                        <div className="seatmapRow" id="row3">
                            <SeatGroupV numberOfGroups={2} numberOfSeatPerGroup={6}></SeatGroupV>
                        </div >
                        <div className="seatmapRow" id="row4">
                            <SeatGroupV numberOfGroups={2} numberOfSeatPerGroup={6}></SeatGroupV>
                        </div >
                        <div className="seatmapRow" id="row5">
                            <SeatGroupV numberOfGroups={2} numberOfSeatPerGroup={9}></SeatGroupV>
                        </div >
                        <div className="seatmapRow" id="row6">
                            <SeatGroupV numberOfGroups={2} numberOfSeatPerGroup={9}></SeatGroupV>
                        </div >
                    </div>

                </div >
            </div >
        </>
    )
}
