export function InfoIcon({size}) {
    return(
        <svg style={{width: `${size}px`, height: `${size}px`}} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
        <circle cx="7" cy="7" r="6.5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></circle>
        <line x1="7" y1="7" x2="7" y2="10.5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></line>
        <circle cx="7" cy="4.5" r="0.5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></circle>
        </g></g></svg>
    )
}

export function TicketIcon({size}) {
    return(
        <svg style={{width: `${size}px`, height: `${size}px`}} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
<g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
    <path d="M4.65,2.24l-.39.09a1.19,1.19,0,0,1-.91,1.43,1.22,1.22,0,0,1-1.44-.92L1.13,3A.81.81,0,0,0,.52,4s.36,1.67.78,3.55" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
    <path d="M12.15,2.23a1.32,1.32,0,1,1-2.44-1L8.9.85a.89.89,0,0,0-1.16.47L4.38,9.23l-.44,1a.89.89,0,0,0,.47,1.16l4.06,1.73a.89.89,0,0,0,1.16-.47l3.8-8.94A.89.89,0,0,0,13,2.58Z" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
  </g></g></svg>
    )
}

export function BackIcon({size}) {
    return(
        <svg style={{width: `${size}px`, height: `${size}px`}} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
<g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
    <polyline points="3.5 1.5 0.5 4.5 3.5 7.5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></polyline>
    <path d="M.5,4.5h9a4,4,0,0,1,0,8h-5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
  </g></g></svg>
    )
}

export function DocumentIcon({size}) {
    return(
        
<svg style={{width: `${size}px`, height: `${size}px`}} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
  <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
      <path d="M7.5,9l-3,.54L5,6.5,10.73.79a1,1,0,0,1,1.42,0l1.06,1.06a1,1,0,0,1,0,1.42Z" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
      <path d="M12,9.5v3a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h3" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
    </g></g></svg>
    )
}

export function DownloadIcon({size}) {
    return(
        
<svg style={{width: `${size}px`, height: `${size}px`}} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
  <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
      <path d="M.5,10.5v1a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2v-1" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></path>
      <polyline points="4 6 7 9.5 10 6" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></polyline>
      <line x1="7" y1="9.5" x2="7" y2="0.5" style={{fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}></line>
    </g></g></svg>
    )
}
