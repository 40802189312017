import './App.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Homepage, Information, Tickets, Samtykkeskjema } from "./pages"
import { Header } from "./components"
import { useState, useEffect } from "react"
import Konami from "react-konami-code"
import { Seatmap } from './pages/Seatmap'
function App() {
  const [rainbowBackground, setRainbowBackground] = useState(false)
  const onKonami = () => {
    console.log("Du fant påskeegget! Nyt fargene.")
    setRainbowBackground(!rainbowBackground)
  }
  useEffect(() => {
    console.log("%cFinn påskeegget!", "color: blue; font-size:32px;")
    console.log("Hint: Du finner det ikke her, men bruk tastaturet.")
  }, [])
  return (
    <div className={`page-container ${(rainbowBackground) ? "rainbow" : ""}`}>
      <Router>
        <Konami action={onKonami} />
        <Header />
        <Routes>
          <Route path="/" exact element={ <Homepage /> } />
          <Route path="/tickets" exact element={ <Tickets /> } />
          <Route path="/info" exact element={ <Information /> } />
          <Route path="/samtykkeskjema" exact element={ <Samtykkeskjema /> } />
          <Route path="/seatmap" exact element={ <Seatmap /> } />
        </Routes>
      </Router>
    </div>
  )
}

export default App
