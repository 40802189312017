import { BackButton, Button, DownloadIcon } from "../components";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export function Samtykkeskjema() {
    useEffect(() => {
        document.title = "TorriLAN - Samtykkeskjema"
     }, []);
    return(
        <div className="wrapper">
            <BackButton />
            <div className="container">
                <h1>Samtykkeskjema</h1>
                <p>Last ned samtykkeskjemaet, få det fylt ut av en foreldre/foresatt og ta det med på arrangementet.
                <br />Deltakere under 18 år vil ikke få tilgang til arrangementet før samtykkeskjema er levert.</p>
                <div className="buttons-wrapper">
                    <Button as={Link} to="/files/samtykkeskjema.pdf" target="_blank">
                        <DownloadIcon size={30} />
                        Last ned samtykkeskjema
                    </Button>
                </div>
            </div>
        </div>
    )
}
