import { BackButton } from "../components"
import { useEffect } from "react"

export function Information() {
    useEffect(() => {
        document.title = "TorriLAN - Informasjon"
    }, [])
    return (
        <div className="wrapper">
            <BackButton />
            <div className="container">
                <h1>Om oss:</h1>
                <p>Ønsker du non-stop gaming gjennom en hel helg? Da er TorriLAN stedet for deg! TorriLAN arrangeres for 33. gang og vi gleder oss til at DU kommer! Gaming, konkurranser i FIFA, Minecraft, Overwatch, aktiviteter som TODO: Insert gym activities i gymsalen, filmvisning på biblioteket, henging med venner og mer, er ting du kan forvente til LAN den 15-17 November 2024! </p>
                <br />
                <p>Lanet arrangeres i regi av Fritidsenheten vest og frivillige ungdom fra Torridal. Alle som er i aldersgruppen 8. klasse – 18. år er hjertelig velkommen til å melde seg på. Inngang koster 230kr og inkluderer sitteplass med stol, soveplass og dusjmuligheter både fredag og lørdag. </p>
                <br />
                <h1>Påmelding</h1>
                <br />
                <p>Er påmeldte under 18 år, blir det stilt krav ved inngangen av Lanet om Samtykkeskjema, eller såkalt foreldrelapp. Denne må underskrives av foreldre og leveres ved betaling. Samtykkeskjemaet finner du på Torrilan.no -&gt; Kjøp Billetter -&gt; Samtykkeskjema. </p>
                <br />
                <h1>Besøk oss!</h1>
                <br />
                <p>Det vil som alltid være mulighet for å besøke lanet! Du kan besøke dine venner på TorriLAN på disse tidspunktene:</p>
                <p>Fredag: 19.00 – 24.00</p>
                <p>Lørdag: 11.00 – 24.00</p>
                <p>Besøkspass koster 75kr per dag</p>
                <br />
                <h1>Regler</h1>
                <br />
                <h1>Regler som gjelder samtlige på TorriLAN:</h1>
                <ul>
                    <li>Det er totalt forbudt med pornografiske bilder eller filmer på de enhetene som skal brukes på lanet. Grove voldsfilmer eller surfing på tvilsomme nettsider er heller ikke lov</li>
                    <li>Upassende oppførsel er ikke lov og kan føre til utestenging(Mobbing, slåssing, vold, rusmidler osv.)</li>
                    <li>Vi gjør oppmerksom på at vi i utgangspunktet ikke har kontroll på hvor alle er til enhver tid. Vi ønsker ikke at lanere forlater området på natten slik at vi har mest mulig kontroll på hvor alle er </li>
                    <li>Det er til en hver tid under hele lanet Crew og ansatte over 18 år på bygget som er trent opp i brannvern og førstehjelp.</li>
                </ul>
            </div>
        </div>
    )
}

/*

                <br />
                <h2>Påmelding</h2>
                <br />
                <p>Er påmeldte under 18 år, blir det stilt krav ved inngangen av Lanet om Samtykkeskjema, eller såkalt foreldrelapp. Denne må underskrives av foreldre og leveres ved betaling. Samtykkeskjemaet finner du på Torrilan.no -&gt; Kjøp Billetter -&gt; Samtykkeskjema. </p>
                <br />
                <h2>Besøk oss!</h2>
                <br />
                <p>Det vil som alltid være mulighet for å besøke lanet! Du kan besøke dine venner på TorriLAN på disse tidspunktene:</p>
                <p>Fredag: 19.00 – 24.00</p>
                <p>Lørdag: 18.00 – 24.00</p>
                <br />
                <h2>Regler</h2>
                <br />
                <h4>Regler som gjelder samtlige på TorriLAN:</h4>
                <ul>
                    <li>Det er totalt forbudt med pornografiske bilder eller filmer på de enhetene som skal brukes på lanet. Grove voldsfilmer eller surfing på tvilsomme nettsider er heller ikke lov</li>
                    <li>Upassende oppførsel er ikke lov og kan føre til utestenging(Mobbing, slåssing, vold, rusmidler osv.)</li>
                    <li>Vi gjør oppmerksom på at vi i utgangspunktet ikke har kontroll på hvor alle er til enhver tid. Vi ønsker ikke at lanere forlater området på natten slik at vi har mest mulig kontroll på hvor alle er </li>
                    <li>Det er til en hver tid under hele lanet Crew og ansatte over 18 år på bygget som er trent opp i brannvern og førstehjelp.</li>
                </ul>*/